import { useCallback, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import {
  useProductHierarchyLabelFormatter,
  useSourceLabelFormatter,
  useTagColorFormatter,
  useTagGetter,
  useTagLabelFormatter,
  useTagSetLabelFormatter,
} from 'reducers/entityLabelFormatter';
import { facetTagSetsSelector } from 'selectors/facet';
import { textSearchValuesSelector } from 'selectors/search';

import {
  createEntityField,
  enrichFieldProps,
} from 'components/customer/campaign/useCampaignFields';
import SortableHeader from 'components/ui/SortableHeader';
import { displayAnonymizedElements } from 'components/ui/Text';
import ReactTableHeader from 'components/ui/table/ReactTableHeader';
import {
  CopyableOpenableTextCell,
  DayCell,
  EllipsedTextCell,
} from 'components/ui/table/cells/ReactTableCell';
import { SentimentDotCell } from 'components/ui/table/cells/dotCells';

import { longDayFormatter } from 'utils/formatter';
import capitalizedTranslation from 'utils/i18n';

import ColorTaggedTextCell from './search/ColorTaggedTextCell';

export const reviewTagAccessor = (tagSetId) => (item) =>
  item.form_element_values?.find(
    ({ tag_set: { id: reviewTagSetId } }) => reviewTagSetId === tagSetId
  )?.tag?.id;

export const reviewChunkTagAccessor = (tagSetId, getTag) => (item) =>
  // Select the first tag only for a given tag set
  // @TODO this is a temporary solution, we should be able to select multiple tags
  item.tags?.find((tagId) => getTag(tagId)?.tag_set?.id === tagSetId);

const chunkSourceAccessor = ({ source }) => source;
const reviewSourceAccessor = ({ source }) => source?.id;
const chunkProductHierarchyAccessor = ({ product_hierarchy }) =>
  product_hierarchy;
const reviewProductHierarchyAccessor = ({ generation }) => generation?.id;

const basicTagSelector = ({ id }) => id;
/**
 * Facet analysis fields generator hook.
 * @param {*} param0
 * @param {*} withTableProps
 * @returns
 */
export function useAnalysisTagFields({
  withTableProps,
  isReviewChunk,
  isReview,
} = {}) {
  const analyzedTagSets = useSelector(facetTagSetsSelector, shallowEqual);
  const getTag = useTagGetter();

  const tagLabelFormatter = useTagLabelFormatter();
  const tagSetLabelFormatter = useTagSetLabelFormatter();
  const tagColorFormatter = useTagColorFormatter();

  const fields = useMemo(() => {
    const selectedFields = [];
    analyzedTagSets.forEach(({ id: tagSetId }) => {
      selectedFields.push(
        createEntityField(
          (isReviewChunk && reviewChunkTagAccessor(tagSetId, getTag)) ||
            (isReview && reviewTagAccessor(tagSetId)) ||
            basicTagSelector,
          {
            id: tagSetId,
            label: tagSetLabelFormatter(tagSetId),
            tag_set: { id: tagSetId },
          },
          withTableProps,
          tagColorFormatter,
          tagLabelFormatter,
          null,
          null,
          false
        )
      );
    });
    return selectedFields;
  }, [analyzedTagSets, tagSetLabelFormatter]);
  return fields;
}

export function useAnalysisColumns({ sortDateAsc, onSwitchSearchSort }) {
  const sourceLabelFormatter = useSourceLabelFormatter(chunkSourceAccessor);
  const productHierarchiesLabelFormatter = useProductHierarchyLabelFormatter(
    chunkProductHierarchyAccessor
  );
  const textSearchValues = useSelector(textSearchValuesSelector);
  const renderTextCell = useCallback(
    ({ value }) => (
      <ColorTaggedTextCell
        value={displayAnonymizedElements(value)}
        textSearchValues={textSearchValues}
      />
    ),
    [textSearchValues]
  );

  const tagColumns = useAnalysisTagFields({
    isReviewChunk: true,
    withTableProps: true,
  });

  const columns = useMemo(
    () => [
      {
        id: 'sentiment',
        accessor: 'sentiment',
        minWidth: 15,
        width: 15,
        maxWidth: 15,
        resizable: false,
        Cell: SentimentDotCell,
      },
      {
        Header: (
          <ReactTableHeader>
            <SortableHeader
              isSorted
              ascending={sortDateAsc}
              label={<Trans id="date" render={capitalizedTranslation} />}
              onSort={onSwitchSearchSort}
            />
          </ReactTableHeader>
        ),
        id: 'date',
        accessor: 'date',
        label: t({ id: 'date' }),
        maxWidth: 80,
        width: 80,
        minWidth: 80,
        resizable: false,
        collapse: true,
        formatter: longDayFormatter,
        // NB : it is important to format only at render time, as displayed format cannot be sorted alphabetically (while isoformat can)
        Cell: DayCell,
        style: { alignSelf: 'center', textAlign: 'center' },
        headerStyle: { borderRadius: '0 16px 0 0' },
      },
      {
        Header: <ReactTableHeader title={t({ id: 'text' })} />,
        id: 'text',
        label: t({ id: 'text' }),
        accessor: 'text',
        minWidth: 200,
        width: 280,
        maxWidth: 600,
        Cell: renderTextCell,
      },
      enrichFieldProps({
        Header: <ReactTableHeader title={t({ id: 'product-service' })} />,
        id: 'product_hierarchy',
        label: t({ id: 'product-service' }),
        accessor: productHierarchiesLabelFormatter,
        minWidth: 50,
        width: 140,
        maxWidth: 300,
      }),
      enrichFieldProps({
        Header: <ReactTableHeader title={t({ id: 'source' })} />,
        id: 'source',
        label: t({ id: 'source' }),
        accessor: sourceLabelFormatter,
        minWidth: 50,
        width: 140,
        maxWidth: 300,
      }),
      ...tagColumns,
    ],
    [
      sourceLabelFormatter,
      productHierarchiesLabelFormatter,
      tagColumns,
      textSearchValues,
      sortDateAsc,
    ]
  );
  return columns;
}

export function useReviewPaneFields() {
  const sourceLabelFormatter = useSourceLabelFormatter(reviewSourceAccessor);
  const productHierarchiesLabelFormatter = useProductHierarchyLabelFormatter(
    reviewProductHierarchyAccessor
  );

  const fields = useMemo(
    () => [
      enrichFieldProps(
        {
          id: 'date',
          accessor: 'date',
          label: t({ id: 'date' }),
          getCellProps: () => ({ inline: true }),
        },
        false,
        { isDateTime: true }
      ),
      enrichFieldProps({
        id: 'product_hierarchy',
        label: t({ id: 'product-service' }),
        accessor: productHierarchiesLabelFormatter,
      }),
      enrichFieldProps({
        id: 'source',
        label: t({ id: 'source' }),
        accessor: (review) =>
          review.source.domain || sourceLabelFormatter(review),
      }),
      enrichFieldProps({
        id: 'url',
        label: t({ id: 'source' }),
        Cell: CopyableOpenableTextCell,
        accessor: (review) => (review.is_url_public && review.url) || null,
        loadingCell: null,
      }),
      enrichFieldProps({
        id: 'title',
        label: t({ id: 'title' }),
        accessor: 'title',
      }),
      enrichFieldProps({
        id: 'external_id',
        label: t({ id: 'external-id' }),
        accessor: 'external_id',
        Cell: EllipsedTextCell,
      }),
    ],
    [sourceLabelFormatter, productHierarchiesLabelFormatter]
  );
  return fields;
}

export default useAnalysisColumns;
