import { useCallback, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { i18n } from '@lingui/core';
import { Trans, msg, t } from '@lingui/macro';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Divider, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  saveFilterPresets,
  actionTypes as searchActionTypes,
  selectFilterPreset,
} from 'actions/search';
import { createLoadingSelector } from 'reducers/ui';
import {
  searchFiltersSelector,
  useNameAvailableSelectorMethod,
} from 'selectors/search';

import Accordion, {
  AccordionItem,
  AccordionToggleTitle,
} from 'components/ui/Accordion';
import ErrorMessage from 'components/ui/ErrorMessage';
import HelpTooltip from 'components/ui/HelpTooltip';
import Segment, { MediumPaddedSegment } from 'components/ui/Segment';
import {
  AnalyticsAwareButton,
  ButtonAccent,
  ButtonTransparentAccent,
} from 'components/ui/button';
import ButtonGroup from 'components/ui/button/ButtonGroup';
import { SecondaryTabButton } from 'components/ui/button/TabButton';
import { AnalyticsAwareCheckbox } from 'components/ui/inputs/Checkbox';
import InTextDropdown from 'components/ui/inputs/InTextDropdown';
import Label from 'components/ui/inputs/Label';
import { LimitedTextInput, TextInput } from 'components/ui/inputs/TextInput';

import commonPropTypes from 'utils/commonPropTypes';
import { capitalize, validateEmail } from 'utils/helpers';
import { useDebounce } from 'utils/hooks';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import FiltersDescription from './FiltersDescription';

const Percentage = styled.span`
  content: '%';
  color: ${svars.accentColor};
  padding-right: ${svars.spaceNormal};
`;

const SumUpTextContainer = styled.div`
  align-items: center;
  justify-content: center;
  width: 95%;
  margin: auto;
  padding: 0;
`;

const translateItems = (items, capitalized = false) =>
  items.map(({ i18nLabel, ...item }) => ({
    ...item,
    text: capitalized ? capitalize(i18n._(i18nLabel)) : i18n._(i18nLabel),
  }));

const NOTIFICATION_PERIOD_ITEMS = [
  { key: '1D', value: '1D', i18nLabel: msg({ id: 'once-a-day' }) },
  { key: '1W', value: '1W', i18nLabel: msg({ id: 'once-a-week' }) },
  { key: '1M', value: '1M', i18nLabel: msg({ id: 'once-a-month' }) },
  { key: '3M', value: '3M', i18nLabel: msg({ id: 'once-a-quarter' }) },
];

const NOTIFICATION_INDICATOR_ITEMS = [
  {
    key: 'n_chunks',
    value: 'n_chunks',
    i18nLabel: msg({ id: 'the-number-of-extracts' }),
    step: 1,
  },
  {
    key: 'average_sentiment',
    value: 'average_sentiment',
    i18nLabel: msg({ id: 'the-sentiment' }),
    step: 0.1,
  },
];

const INDICATOR_CONDITION_ITEMS = [
  {
    key: 'volume',
    value: {
      condition_field: 'n_chunks',
      is_threshold_relative: false,
      threshold: 150,
    },
    i18nLabel: msg({ id: 'volume' }),
  },
  {
    key: 'volume-%',
    value: {
      condition_field: 'n_chunks',
      is_threshold_relative: true,
      threshold: 5,
    },
    i18nLabel: msg({ id: 'volume-%' }),
  },
  {
    key: 'sentiment',
    value: {
      condition_field: 'average_sentiment',
      is_threshold_relative: false,
      threshold: 0.2,
    },
    i18nLabel: msg({ id: 'sentiment' }),
  },
];

const FormFieldInput = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  margin-left: ${svars.spaceMediumLarge};
`;
const FormFieldContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
function FormField({ label, help, input }) {
  return (
    <FormFieldContainer>
      <Label style={{ width: '30%', textAlign: 'end' }}>
        {label}
        <HelpTooltip help={help} />
      </Label>
      <FormFieldInput>{input}</FormFieldInput>
    </FormFieldContainer>
  );
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  help: PropTypes.string.isRequired,
  input: PropTypes.node.isRequired,
};

const areEqualValues = (value1, value2) =>
  value1.condition_field === value2.condition_field &&
  value1.is_threshold_relative === value2.is_threshold_relative;

/**
 * Compute a default form state based on the default values and the preset to update.
 * NB : this is specifically handled for the each of notification parameters because
 * a notification can exist without the all parameters being defined (ex: `condition_field` is empty, but we need a default as we cannot leave it empty).
 *
 * @param {*} updateDefaults the payload to use as ground truth for the default values.
 */
const getDefaultFormState = (updateDefaults) => ({
  ...updateDefaults,
  period: updateDefaults?.period || NOTIFICATION_PERIOD_ITEMS[0].value,
  condition_field:
    updateDefaults?.condition_field || NOTIFICATION_INDICATOR_ITEMS[0].value,
  is_threshold_relative: updateDefaults?.is_threshold_relative || false,
  threshold: updateDefaults?.threshold || 10,
});

function FilterPresetManagementModal({
  modalIsOpen,
  onClose,
  updatePreset,
  ctaIsLoading,
}) {
  const dispatch = useDispatch();
  const filtersValues = useSelector(searchFiltersSelector);
  const [presetName, setPresetName] = useState(updatePreset.name || '');
  const [configureNotification, setConfigureNotification] = useState(
    !!get(updatePreset, 'notification.period')
  );
  const [notificationParameters, setNotificationParameters] = useState(
    getDefaultFormState(
      get(updatePreset, 'notification') || INDICATOR_CONDITION_ITEMS[0].value
    )
  );
  const [addCondition, setAddCondition] = useState(
    !!get(updatePreset, 'notification.threshold')
  );
  const updateEmail = get(updatePreset, 'notification.custom_email');
  const updateDoSendMail = get(updatePreset, 'notification.send_mail');
  const [email, setEmail] = useState(updateEmail || '');
  const [doSendMail, setDoSendMail] = useState(updateDoSendMail);
  // Checkbox is initially checked if there exists a custom email
  const [useCustomEmail, setUseCustomEmail] = useState(!!email);

  const defaultFormState = getDefaultFormState(
    get(updatePreset, 'notification') || INDICATOR_CONDITION_ITEMS[0].value
  );
  const doPurgeNotifications =
    notificationParameters.condition_field !==
      defaultFormState.condition_field ||
    notificationParameters.is_threshold_relative !==
      defaultFormState.is_threshold_relative ||
    !configureNotification ||
    !addCondition;
  const onToggleCustomEmail = useCallback(() => {
    if (useCustomEmail && email) {
      setEmail('');
    }
    setUseCustomEmail(!useCustomEmail);
  }, [useCustomEmail, setUseCustomEmail]);

  const onSetConfigureNotification = useCallback(
    () => setConfigureNotification(!configureNotification),
    [configureNotification, setConfigureNotification]
  );
  const onSetNotificationParameters = useCallback(
    (_, { value: diff }) =>
      setNotificationParameters({ ...notificationParameters, ...diff }),
    [notificationParameters, setNotificationParameters]
  );
  const onUpdateDoSendMail = useCallback(
    () => setDoSendMail(!doSendMail),
    [doSendMail, setDoSendMail]
  );
  const onSetPresetName = useCallback(
    (e, { value }) => setPresetName(value),
    [setPresetName]
  );
  const onAddCondition = useCallback(() => {
    setAddCondition(!addCondition);
    // setDoPurgeNotifications(true, notificationParameters);
  }, [setAddCondition, addCondition]);
  const onSetEmail = useCallback((e, { value }) => setEmail(value), [setEmail]);
  const debouncedEmail = useDebounce(email, 200);
  const indicatorItem = notificationParameters.condition_field
    ? NOTIFICATION_INDICATOR_ITEMS.find(
        ({ value }) => value === notificationParameters.condition_field
      )
    : {};
  const savePreset = useCallback(() => {
    dispatch(
      saveFilterPresets(
        updatePreset ? updatePreset.id : null,
        (updatePreset &&
          updatePreset.notification &&
          updatePreset.notification.id) ||
          null,
        presetName,
        filtersValues,
        configureNotification,
        notificationParameters.period,
        addCondition,
        notificationParameters,
        doSendMail,
        email,
        doPurgeNotifications
      )
    ).then((value) => {
      if (!updatePreset?.id || updatePreset.id === value) {
        dispatch(selectFilterPreset(null, { value }));
      }
      onClose();
    });
  }, [
    presetName,
    filtersValues,
    configureNotification,
    notificationParameters.period,
    addCondition,
    notificationParameters,
    doSendMail,
    email,
    onClose,
    doPurgeNotifications,
  ]);

  const emailIsValid = validateEmail(debouncedEmail);
  const isNameAvailableMethod = useNameAvailableSelectorMethod();
  const isNameAvailable = isNameAvailableMethod([presetName, updatePreset?.id]);

  const formIsValid =
    isNameAvailable &&
    presetName &&
    (!addCondition ||
      (notificationParameters.threshold &&
        notificationParameters.threshold >= 0)) &&
    (!doSendMail || !useCustomEmail || emailIsValid);
  const isUpdate = !!(updatePreset && updatePreset.name);
  return (
    <Modal closeIcon onClose={onClose} open={modalIsOpen}>
      <Modal.Header
        content={
          isUpdate
            ? t({ id: 'update-existing-filter-preset' })
            : t({ id: 'create-a-new-preset' })
        }
      />
      <Modal.Content
        style={{ minHeight: '40vh', maxHeight: '75vh', display: 'flex' }}
      >
        <span style={{ width: '100%', overflowY: 'auto' }}>
          <Segment
            style={{ padding: `${svars.spaceMedium} ${svars.spaceLarge}` }}
          >
            <LimitedTextInput
              style={{
                width: '100%',
                maxWidth: '780px',
                margin: 'auto',
                fontSize: svars.fontSizeLarge,
              }}
              value={presetName}
              placeholder={t({ id: 'set-a-preset-name' })}
              onChange={onSetPresetName}
              maxCharacters={80}
              autoFocus={!presetName}
              error={!isNameAvailable}
            />
            <ErrorMessage
              message={t({
                id: 'filter-preset-name-is-taken-select-an-other-one',
              })}
              show={!isNameAvailable}
            />
          </Segment>
          <MediumPaddedSegment style={{ margin: 0, padding: 0 }}>
            <Accordion fluid style={{ padding: 0 }}>
              <AccordionToggleTitle
                title={<Trans id="add-a-notification" />}
                help={t({ id: 'notification.add-a-notification-helper' })}
                active={configureNotification}
                onToggle={onSetConfigureNotification}
              />
              <Accordion.Content active={configureNotification}>
                <SumUpTextContainer>
                  <Trans id="the-notification-will-be-triggered" />
                  <InTextDropdown
                    style={{ paddingLeft: svars.spaceNormal }}
                    options={translateItems(NOTIFICATION_PERIOD_ITEMS, false)}
                    value={notificationParameters.period}
                    onChange={(e, { value }) =>
                      onSetNotificationParameters(null, {
                        value: {
                          period: value,
                        },
                      })
                    }
                  />
                </SumUpTextContainer>
                <Divider />
                <Accordion>
                  <AccordionItem
                    title={<Trans id="add-a-condition" />}
                    help={t({ id: 'notification.add-a-condition-helper' })}
                    active={addCondition}
                    onToggle={onAddCondition}
                    content={
                      <>
                        <ButtonGroup
                          buttonBottomPadded
                          as={SecondaryTabButton}
                          items={INDICATOR_CONDITION_ITEMS}
                          areValuesEqual={areEqualValues}
                          onChange={onSetNotificationParameters}
                          value={notificationParameters}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: 'auto',
                            flexWrap: 'wrap',
                          }}
                        />
                        <SumUpTextContainer>
                          <Trans>
                            The notification will be sent if
                            <b
                              style={{
                                padding: `0 ${svars.spaceNormal}`,
                                color: svars.accentColor,
                              }}
                            >
                              {indicatorItem.i18nLabel
                                ? i18n._(indicatorItem.i18nLabel)
                                : ''}
                            </b>
                            varies from
                            <TextInput
                              type="number"
                              min="0"
                              error={notificationParameters.threshold < 0}
                              step={indicatorItem.step}
                              required
                              fluid
                              onChange={(e, { value }) =>
                                onSetNotificationParameters(e, {
                                  value: {
                                    threshold: value,
                                  },
                                })
                              }
                              value={notificationParameters.threshold}
                              style={{
                                display: 'inline-flex',
                                width:
                                  notificationParameters.is_threshold_relative
                                    ? '70px'
                                    : '90px',
                                margin: `${svars.spaceNormal} 0`,
                                padding: `0 ${svars.spaceNormalLarge}`,
                              }}
                            />
                            {notificationParameters.is_threshold_relative ? (
                              <Percentage>%</Percentage>
                            ) : null}
                            or more since last notification.
                          </Trans>
                        </SumUpTextContainer>
                      </>
                    }
                  />
                  <Divider />
                  <AccordionItem
                    title={<Trans id="get-notified-by-email" />}
                    help={t({
                      id: 'notification.get-notified-by-email-helper',
                    })}
                    active={doSendMail}
                    onToggle={onUpdateDoSendMail}
                    content={
                      <SumUpTextContainer>
                        <Trans id="you-will-be-notified-by-mail" />
                        <div style={{ paddingTop: svars.spaceNormal }}>
                          <AnalyticsAwareCheckbox
                            gaCategory="Dashboard - search"
                            gaAction="Notify custom email"
                            gaLabel={`isUpdate=${isUpdate}`}
                            checked={useCustomEmail}
                            onClick={onToggleCustomEmail}
                            label={
                              // eslint-disable-next-line jsx-a11y/label-has-associated-control
                              <label style={{ margin: svars.spaceNormal }}>
                                <Trans id="use-an-other-email" />
                              </label>
                            }
                          />
                          {useCustomEmail && (
                            <TextInput
                              icon="user"
                              iconPosition="left"
                              placeholder={t({ id: 'email' })}
                              error={!emailIsValid}
                              value={email}
                              onChange={onSetEmail}
                              style={{
                                marginLeft: svars.spaceMedium,
                                minWidth: '300px',
                              }}
                            />
                          )}
                        </div>
                      </SumUpTextContainer>
                    }
                  />
                </Accordion>
              </Accordion.Content>
            </Accordion>
          </MediumPaddedSegment>
        </span>
        <FiltersDescription
          filtersValues={filtersValues}
          onCloseModal={onClose}
        />
      </Modal.Content>
      <Modal.Actions>
        <AnalyticsAwareButton
          gaCategory="Dashboard - search"
          gaAction="Close preset modal"
          gaLabel={`isUpdate=${isUpdate}`}
          inputComponent={ButtonTransparentAccent}
          type="submit"
          onClick={onClose}
          style={{ marginRight: svars.spaceMediumLarge }}
        >
          <Trans render={capitalizedTranslation} id="cancel" />
        </AnalyticsAwareButton>
        <AnalyticsAwareButton
          inputComponent={ButtonAccent}
          gaCategory="Dashboard - search"
          gaAction={isUpdate ? 'Update preset' : 'Create preset'}
          gaLabel={`condition=${addCondition}&mail=${!!doSendMail}`}
          disabled={!formIsValid}
          type="submit"
          onClick={savePreset}
          loading={ctaIsLoading}
        >
          {isUpdate ? <Trans id="save" /> : <Trans id="create" />}
        </AnalyticsAwareButton>
      </Modal.Actions>
    </Modal>
  );
}

FilterPresetManagementModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool,
  updatePreset: commonPropTypes.filterPreset,
  ctaIsLoading: PropTypes.bool,
};

FilterPresetManagementModal.defaultProps = {
  modalIsOpen: false,
  updatePreset: {},
  ctaIsLoading: false,
};

const actionLoadingSelector = createLoadingSelector([
  searchActionTypes.SAVE_FILTER_PRESETS_REQUEST,
]);

export default connect((state) => ({
  ctaIsLoading: actionLoadingSelector(state),
}))(FilterPresetManagementModal);
